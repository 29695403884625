import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { flashMessagesReducer } from './flash-messages.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('feature.flashMessages', flashMessagesReducer),
    EffectsModule.forFeature([]),
  ],
})
export class FlashMessagesStoreModule {}
