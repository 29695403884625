import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { SessionActions } from '../store/session.actions';
import { KeycloakService } from 'keycloak-angular';
import { Observable, Subscription } from 'rxjs';
import { selectRoles } from '../store/session.selector';

@Injectable({
  providedIn: 'root',
})
export class SessionStateService implements OnDestroy {
  private keycloakSubscription: Subscription;

  constructor(private store: Store, private keycloakService: KeycloakService) {
    this.keycloakSubscription = this.keycloakService.keycloakEvents$.subscribe({
      next: (e) => {
        const roles = this.keycloakService.getUserRoles(true);
        this.setSessionUserRoles(roles);
      },
    });
    this.setSessionUserRoles(this.keycloakService.getUserRoles(true));
  }

  ngOnDestroy(): void {
    this.keycloakSubscription?.unsubscribe();
  }

  launchLogoutProcess() {
    this.store.dispatch(SessionActions.logout());
  }

  launchLoginProcess() {
    this.store.dispatch(SessionActions.login());
  }

  public roles$(): Observable<string[]> {
    return this.store.select(selectRoles);
  }

  private setSessionUserRoles(roles: string[]) {
    this.store.dispatch(SessionActions.setUserRoles({ roles }));
  }
}
