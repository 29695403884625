<div class="flash-message-container">
  <div>
    <app-flash-message
      (whenMessageCloseClicked)="whenMessageCloseClicked(flashMessage)"
      *ngFor="let flashMessage of flashMessages$ | async"
      [flashMessage]="flashMessage"
    >
      {{ flashMessage.message }}
    </app-flash-message>
  </div>
</div>
