import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesAuthGuard } from './base/guards/roles-auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./site/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./site/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: 'contacts',
    canActivate: [RolesAuthGuard],
    data: { rolesOneOf: ['MANAGER'] },
    loadChildren: () =>
      import(
        './site/contacts/components/contacts-site/contacts-site.module'
      ).then((m) => m.ContactsSiteModule),
  },
  {
    path: 'grants',
    canActivate: [RolesAuthGuard],
    data: { rolesOneOf: ['MANAGER'] },
    loadChildren: () =>
      import('./site/grants/components/grants-site/grants-site.module').then(
        (m) => m.GrantsSiteModule,
      ),
  },
  {
    path: 'invoices',
    canActivate: [RolesAuthGuard],
    data: { rolesOneOf: ['MANAGER'] },
    loadChildren: () =>
      import(
        './site/invoices/components/invoices-site/invoices-site.module'
      ).then((m) => m.InvoicesSiteModule),
  },
  {
    path: 'coaching',
    canActivate: [RolesAuthGuard],
    data: { rolesOneOf: ['MANAGER'] },
    loadChildren: () =>
      import(
        './site/coaching/components/coaching-site/coaching-site.module'
      ).then((m) => m.CoachingSiteModule),
  },
  {
    path: 'membership-requests',
    canActivate: [RolesAuthGuard],
    data: { rolesOneOf: ['MANAGER'] },
    loadChildren: () =>
      import(
        './site/membership-requests/components/membership-requests-site/membership-requests-site.module'
      ).then((m) => m.MembershipRequestsSiteModule),
  },
  {
    path: 'administration',
    canActivate: [RolesAuthGuard],
    data: { rolesOneOf: ['MANAGER'] },
    loadChildren: () =>
      import(
        './site/administration/components/administration-site/administration-site.module'
      ).then((m) => m.AdministrationSiteModule),
  },
  {
    path: 'events',
    canActivate: [RolesAuthGuard],
    data: { rolesOneOf: ['MANAGER'] },
    loadChildren: () =>
      import('./site/events/components/events-site/events-site.module').then(
        (m) => m.EventsSiteModule,
      ),
  },
  {
    path: 'evaluations',
    canActivate: [RolesAuthGuard],
    data: { rolesOneOf: ['MANAGER'] },
    loadChildren: () =>
      import(
        './site/evaluations/components/evaluations-site/evaluations-site.module'
      ).then((m) => m.EvaluationsSiteModule),
  },
  {
    path: 'maillists',
    canActivate: [RolesAuthGuard],
    data: { rolesOneOf: ['MANAGER'] },
    loadChildren: () =>
      import(
        './site/maillist/components/maillist-site/maillist-site.module'
      ).then((m) => m.MaillistSiteModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
