export const environment = {
  keycloakUrl: 'https://auth-test.desideria.org',
  keycloakClientId: 'desideria-verwaltung-frontend-test',
  keycloakRealm: 'desideriacare-verwaltung-test',
  keycloakAccountUrl:
    'https://auth-test.desideria.org/realms/desideriacare-verwaltung-test/account',

  // api
  apiUrl: 'https://api-test.desideria.org',
  stage: 'test',
};
