import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlashMessagesState } from './flash-messages.types';

export const selectFlashMessagesState =
  createFeatureSelector<FlashMessagesState>('feature.flashMessages');

export const selectFlashMessages = createSelector(
  selectFlashMessagesState,
  (state: FlashMessagesState) => [...state.flashMessages],
);
