<div
  [class.error]="flashMessage?.severity === 'error'"
  [class.info]="flashMessage?.severity === 'info'"
  [class.success]="flashMessage?.severity === 'success'"
  [class.warning]="flashMessage?.severity === 'warning'"
  class="flash-message-msg mat-elevation-z2"
>
  <div class="flash-icon">
    <mat-icon *ngIf="flashMessage?.severity === 'error'">error</mat-icon>
    <mat-icon *ngIf="flashMessage?.severity === 'info'">info</mat-icon>
    <mat-icon *ngIf="flashMessage?.severity === 'warning'">warning</mat-icon>
    <mat-icon *ngIf="flashMessage?.severity === 'success'"
      >check_circle
    </mat-icon>
  </div>
  <div class="flash-content">
    <ng-content></ng-content>
  </div>
  <div class="flash-close">
    <mat-icon (click)="clickClose(flashMessage)">close</mat-icon>
  </div>
</div>
