import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { SessionStateService } from '../services/session/state/session-state.service';

@Injectable({
  providedIn: 'root',
})
export class RolesAuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected override readonly keycloakAngular: KeycloakService,
    private readonly sessionStateService: SessionStateService,
  ) {
    super(router, keycloakAngular);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      this.sessionStateService.launchLoginProcess();
      return false;
    }

    // Get the roles required from the route.
    const requiredRolesOnOf = route.data['rolesOneOf'];

    // Allow the user to proceed if no additional roles are required to access the route.
    if (
      !(requiredRolesOnOf instanceof Array) ||
      requiredRolesOnOf.length === 0
    ) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRolesOnOf.some((role) => this.roles.includes(role));
  }
}
