import { createAction, props } from '@ngrx/store';

import * as SessionActions from './session.actions';

export { SessionActions };

export const login = createAction(`[SessionActions] login`);
export const logout = createAction(`[SessionActions] logout`);
export const setUserRoles = createAction(
  `[SessionActions] setUserRoles`,
  props<{ roles: string[] }>(),
);
