import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionState } from './session.types';

export const selectSessionState =
  createFeatureSelector<SessionState>('feature.session');

export const selectRoles = createSelector(
  selectSessionState,
  (state: SessionState) => state.roles,
);
