import * as FlashMessagesActions from './flash-messages.actions';
import { createAction, props } from '@ngrx/store';
import { FlashMessage } from '../../model/flash-message.types';

export { FlashMessagesActions };

export const addFlashMessageAction = createAction(
  `[FlashMessagesActions] addFlashMessageAction`,
  props<{ flashMessage: FlashMessage }>(),
);

export const removeFlashMessageAction = createAction(
  `[FlashMessagesActions] removeFlashMessageAction`,
  props<{ id: string }>(),
);
