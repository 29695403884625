import { Component } from '@angular/core';
import { FlashMessageStateService } from '../data/state/flash-message-state.service';
import { Observable } from 'rxjs';
import { FlashMessage } from '../model/flash-message.types';

@Component({
  selector: 'app-flash-message-outlet',
  templateUrl: './flash-message-outlet.component.html',
  styleUrls: ['./flash-message-outlet.component.scss'],
})
export class FlashMessageOutletComponent {
  flashMessages$: Observable<FlashMessage[]>;

  constructor(private flashMessageStateService: FlashMessageStateService) {
    this.flashMessages$ = this.flashMessageStateService.flashMessages$();
  }

  whenMessageCloseClicked(flashMessage: FlashMessage) {
    this.flashMessageStateService.removeFlashMessage(flashMessage.id);
  }
}
