import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlashMessageOutletComponent } from './flash-message-outlet.component';
import { FlashMessagesStoreModule } from '../data/store/flash-messages-store.module';
import { FlashMessageComponent } from './flash-message/flash-message.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [FlashMessageOutletComponent, FlashMessageComponent],
  exports: [FlashMessageOutletComponent],
  imports: [CommonModule, FlashMessagesStoreModule, MatIconModule],
})
export class FlashMessageOutletModule {}
