import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FlashMessage } from '../../model/flash-message.types';

@Component({
  selector: 'app-flash-message',
  templateUrl: './flash-message.component.html',
  styleUrls: ['./flash-message.component.scss'],
})
export class FlashMessageComponent implements OnDestroy, AfterViewInit {
  @Input() flashMessage: FlashMessage | undefined;
  @Output() whenMessageCloseClicked = new EventEmitter<FlashMessage>();
  private timeoutId: number | undefined;

  clickClose(flashMessage: FlashMessage | undefined) {
    clearTimeout(this.timeoutId);
    if (flashMessage) {
      this.whenMessageCloseClicked.emit(flashMessage);
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }

  ngAfterViewInit(): void {
    if (this.flashMessage?.destroyAfter) {
      this.timeoutId = window.setTimeout(() => {
        this.clickClose(this.flashMessage);
      }, this.flashMessage.destroyAfter - Date.now());
    }
  }
}
